import { ICurrentUser } from "@/interfaces/auth.interface";
import { IScrollTracks } from "@/interfaces/common.interface";
import { atom, RecoilState } from "recoil";

export const currentUserState: RecoilState<Partial<ICurrentUser>> = atom({
  key: "currentUser",
  default: {},
});

export const commonState: RecoilState<IScrollTracks> = atom({
  key: "common",
  default: {},
});

export const loadingState: RecoilState<{ loading: boolean }> = atom({
  key: "loading",
  default: {
    loading: false,
  },
});

export const routerState: RecoilState<{ redirect: string }> = atom({
  key: "router",
  default: {
    redirect: "",
  },
});

export const sideBarState: RecoilState<{ sideBar: boolean }> = atom({
  key: "sidebar",
  default: {
    sideBar: false,
  },
});

export const docsBarState: RecoilState<boolean> = atom({
  key: "docsBar",
  default: false,
});

export const immersiveLayzerState: RecoilState<boolean> = atom({
  key: "immersiveLayzer",
  default: false,
});
