import { message } from "antd";
import _copy from "copy-to-clipboard";
import _ from "lodash";

export const isServer = typeof window === "undefined";
export const isClient = typeof window !== "undefined";

export const underConstruction = () => {
  message.info("Comming soon!");
};

export const iff = (condition: any, then: any, otherwise: any) =>
  condition ? then : otherwise;

export const randomSearchBg = () => {
  return _.sample(
    _.shuffle([
      require("@/assets/images/banner/search_bg_1.jpg"),
      require("@/assets/images/banner/search_bg_3.jpg"),
      require("@/assets/images/banner/search_bg_4.jpg"),
      require("@/assets/images/banner/search_bg_5.jpg"),
      require("@/assets/images/banner/search_bg_7.jpg"),
      require("@/assets/images/banner/search_bg_8.jpg"),
      require("@/assets/images/banner/search_bg_11.jpg"),
      require("@/assets/images/banner/search_bg_15.jpg"),
      require("@/assets/images/banner/search_bg_17.jpg"),
      require("@/assets/images/banner/search_bg_20.jpg"),
      require("@/assets/images/banner/search_bg_22.jpg"),
      require("@/assets/images/banner/search_bg_27.jpg"),
      require("@/assets/images/banner/search_bg_28.jpg"),
      require("@/assets/images/banner/search_bg_29.jpg"),
      require("@/assets/images/banner/search_bg_30.jpg"),
      require("@/assets/images/banner/search_bg_31.jpg"),
    ])
  );
};

export const customWidth = () => ({
  rows: 30,
  width: [
    "80%",
    "90%",
    "100%",
    "80%",
    "0%",
    "70%",
    "80%",
    "80%",
    "100%",
    "80%",
    "0%",
    "70%",
    "100%",
    "100%",
    "80%",
    "100%",
    "0%",
    "100%",
    "100%",
    "70%",
    "0%",
    "85%",
    "100%",
    "70%",
    "100%",
    "0%",
    "85%",
    "100%",
    "70%",
    "100%",
  ],
});

export const copy = (target: string) => {
  _copy(target);
  message.success("Copied");
};
